import Instance from '../request';

export default {
  // 销售成交金额
  getBaseInfo() {
    return Instance.get('/data-view/crm/basic');
  },
  // 销售成交金额
  getSaleInfo(params) {
    return Instance.get('/data-view/crm/sale-rank', { params });
  },
  // 所有销售指标
  getMetricsAll(params) {
    return Instance.get('/data-view/crm/metrics', { params });
  }
};
