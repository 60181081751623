import Instance from '../request';
import { base as baseURL } from '../verson.js'

export default {
  // 获取基准数据
  getInfo() {
    return Instance.get('/data-view/data');
  },
  getWebConfig() {
    console.log(baseURL + '')
    return Instance.get('/web/config', { baseURL })
  },

  // 获取基础数据
  getBasicInfo() {
    return Instance.get('/data-view/source/basic');
  },

  // 获取热门企业、规模分布、行业分布
  getHotCompany(data) {
    return Instance.get('/data-view/source/company', { params: data });
  },

  // 获取求职者、学历分布、工作经验分布
  getSeekerInfo() {
    return Instance.get('/data-view/source/seeker');
  },

  // 获取热词和使用量
  getHotKeywords() {
    return Instance.get('/data-view/source/hot-keywords');
  },

  // 获取职位数变化趋势
  getTrendJobs() {
    return Instance.get('/data-view/source/trend/jobs');
  },

  // 获取热门区域
  getHotRegion(data) {
    return Instance.get('/data-view/source/company-region', { params: data });
  },

  // 获取企业实时数据
  getActualCompany(data) {
    return Instance.get('/data-view/source/company-live-log', { params: data });
  },

  // 获取求职实时数据
  getActualseeker(data) {
    return Instance.get('/data-view/source/seeker-live-log', { params: data });
  },

  // 获取隐私号数据
  getPrivacyCount() {
    return Instance.get('/data-view/source/privacy');
  }
};
